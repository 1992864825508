import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";

const LoadingDialog = () => {
  //#region STYLES
  //#endregion

  return (
    <Dialog open={true} fullWidth={true}>
      <DialogTitle>Wait...!</DialogTitle>
      <DialogContent dividers style={{ display: "flex", alignItems: "center" }}>
        <CircularProgress style={{ marginRight: "10px" }} />
        <Typography> Loading</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
