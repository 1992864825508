import { useEffect, useRef } from "react";
import { useWindowSize } from "./hooks/useWindowSize";
import { screenSizeData } from "./utils/ScreenManager";
import { PathsUrls } from "./utils/ImiData";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import GamePage from "./Pages/GamePage";
import BasePage from "./Pages/BasePage";

function App() {
  //#region HOOKS
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] = useWindowSize();

  const userTokenRef = useRef(null);

  useEffect(() => {
    const url = new URL(window.location);
    const urlParts = new URL(url).hostname.split(".");
    const domain = urlParts.slice(-2).join(".");
    document.domain = domain;

    //RETRIEVE DATA ON PAGE REFRESH
    const token = new URL(window.location.href).searchParams.get("token");

    if (token) {
      userTokenRef.current = token;
    }
  }, []);

  //#endregion

  //#region UTIL FNS
  const updateUserToken = (newToken) => {
    userTokenRef.current = newToken;
  };
  //#endregion

  return (
    <BasePage userToken={userTokenRef} updateUserTokenFn={updateUserToken}>
      <Routes>
        <Route path="/" element={<Navigate to={PathsUrls.home} />} />
        <Route path={PathsUrls.home} element={<HomePage />} />
        <Route path={PathsUrls.game} element={<GamePage userToken={userTokenRef} />} />
      </Routes>
    </BasePage>
  );
}

export default App;
