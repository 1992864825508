import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetVideoUrlMutation } from "../../services/keellsAPI";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";

export default function VideoConformationDialog({ closeFn, videoUrlRef }) {
  //#region HOOKS
  const [stateIndex, setStateIndex] = useState(1);

  //GET VIDEO API
  const [getVideoUrlFn, { isLoading: isLoadingGetVideoAPI, error: getVideoAPIError, isSuccess: isSuccessGetVideoAPI, data: getVideoAPIData }] = useGetVideoUrlMutation();

  useEffect(() => {
    if (isSuccessGetVideoAPI) {
      setStateIndex(0);
      videoUrlRef.current = getVideoAPIData.data;
      closeFn(true);
    }
  }, [isSuccessGetVideoAPI]);

  //#endregion

  //#region STATE CONTENT
  let stateContent = null;

  if (stateIndex === 1) {
    stateContent = (
      <Dialog fullWidth={true} open={true}>
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            Watch a video and win a re-try
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeFn(false);
            }}
          >
            <Typography
              color="black"
              style={{
                textTransform: "none",
              }}
              noWrap={true}
            >
              Skip
            </Typography>
          </Button>
          <Button
            onClick={() => {
              getVideoUrlFn();
              setStateIndex(2);
            }}
          >
            <Typography
              color="text.neutral"
              noWrap={true}
              style={{
                textTransform: "none",
              }}
            >
              Watch
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (stateIndex === 2) {
    if (isLoadingGetVideoAPI) {
      stateContent = <LoadingDialog />;
    } else if (getVideoAPIError) {
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {}}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong...!",
          }}
        />
      );
    }
  }
  //#endregion

  return stateContent;
}
