import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { ControlBar, Player } from "video-react";
import { getElementWidth } from "../../utils/ScreenManager";
import "../../../node_modules/video-react/dist/video-react.css";
import "./video.css";

const VideoDialog = ({ closeFn, videoUrlRef }) => {
  //#region HOOKS
  const playerRef = useRef(null);
  const [videoState, setVideoState] = useState(null);
  const [hasEnded, setHasEnded] = useState(false);

  const [openVideoSkipDialog, setOpenVideoSkipDialog] = useState(false);

  useEffect(() => {
    // Copying the player's state to a local component state on every change
    subscribeToPlayerStateChange();
  }, []);

  useEffect(() => {
    if (videoState && videoState.ended && !hasEnded) {
      console.log("VIDEO OVER");
      setHasEnded(true);
      closeFn({ type: "VIDEO", status: true });
    }
  }, [videoState]);
  //#endregion

  //#region UTIL FNS
  const subscribeToPlayerStateChange = () => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange(setVideoState);
    } else {
      setTimeout(() => {
        subscribeToPlayerStateChange();
      }, 1000);
    }
  };

  //#endregion

  //#region VIDEO SKIP DIALOG
  let videoCancelDialogContent = (
    <Dialog fullWidth={true} open={true}>
      <DialogContent>
        <DialogContentText
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          Skipping this video will lose your re-try
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            closeFn({ type: "VIDEO", status: false });
          }}
        >
          <Typography
            style={{
              textTransform: "none",
            }}
          >
            Skip
          </Typography>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setOpenVideoSkipDialog(false);
            playerRef.current.play();
          }}
        >
          <Typography
            style={{
              textTransform: "none",
            }}
          >
            Continue Watching
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
  //#endregion

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <DialogContent sx={{ display: "flex", padding: "0px" }}>
          <Box sx={{ maxHeight: "100vh", backgroundColor: "transparent", width: "100vw", alignItems: "center", position: "relative" }}>
            <Player playsInline aspectRatio="16:9" width="100%" height="100%" ref={playerRef} src={videoUrlRef.current} autoPlay={true} loop={false}>
              <ControlBar disableCompletely={true} />
            </Player>
          </Box>
          <Button
            style={{ position: "absolute", backgroundColor: "rgb(0,54,0)", bottom: "0", right: "0" }}
            color="primary"
            onClick={() => {
              playerRef.current.pause();
              //closeFn({ type: "VIDEO", status: false });
              setOpenVideoSkipDialog(true);
            }}
          >
            <Typography
              style={{
                textTransform: "none",
                fontSize: getElementWidth(30),
                textAlign: "left",
                color: "white",
              }}
            >
              Skip
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>
      {openVideoSkipDialog && videoCancelDialogContent}
    </>
  );
};

export default VideoDialog;
