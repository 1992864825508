import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PathsUrls } from "../utils/ImiData";

const GamePage = ({ userToken }) => {
  const gameUrl = useSelector((state) => state.home.gameUrl);
  const navigate = useNavigate();

  useEffect(() => {
    if (!gameUrl) {
      navigate(PathsUrls.home, {
        replace: true,
      });
    }
  }, []);

  let url = "";

  if (gameUrl) {
    url = `${gameUrl}?platform=pwa&version=200`;

    if (userToken.current) {
      url += `&token=${userToken.current}`;
    }
  }
  console.log(url);

  return (
    <iframe
      id="gameIframe"
      title="gameFrame"
      src={url}
      allow="microphone; accelerometer; encrypted-media"
      style={{
        width: "100%",
        height: "100%",
        border: "0",
        position: "fixed",
        top: "0",
        left: "0",
        overflow: "hidden",
      }}
    ></iframe>
  );
};

export default GamePage;
