import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CouponDialog from "./Components/CouponDialog";
import EmailDialog from "./Components/EmailDialog";
import VideoConformationDialog from "./Components/VideoConformationDialog";
import VideoDialog from "./Components/VideoDialog";

const BasePage = ({ children, userToken, updateUserTokenFn }) => {
  //#region HOOKS
  const location = useLocation();
  const videoUrlRef = useRef(null);
  //#endregion

  //#region JS BRIDGE COMMANDS
  const eventListener = (event) => {
    if (typeof event.data === "string") {
      //CHECK FOR JSON OBJECT
      try {
        let commandObj = JSON.parse(event.data);
        console.log(commandObj);

        if (commandObj.command === "OPEN_EMAIL") {
          if (!openEmailDialog) {
            updateUserTokenFn(commandObj.token);
            setOpenEmailDialog(true);
          }
        } else if (commandObj.command === "OPEN_VIDEO") {
          if (!videoConfirmationDialog) {
            setVideoConfirmationDialog(true);
          }
        } else if (commandObj.command === "OPEN_COUPON") {
          if (!openCouponCodeDialog) {
            setOpenCouponCodeDialog(true);
          }
        }
      } catch (e) {
        console.log("Bridge Error : " + e);
      }
    }
  };

  const eventListenerFnRef = useRef(eventListener);

  useEffect(() => {
    window.removeEventListener("message", eventListenerFnRef.current);
    eventListenerFnRef.current = (event) => eventListener(event);
    window.addEventListener("message", eventListenerFnRef.current, false);
  }, [location]);
  //#endregion

  //#region UTIL FNS
  const sendDataToIframe = (data) => {
    try {
      document.getElementById("gameIframe").contentWindow.onBridgeDataUpdate(data);
    } catch (e) {
      console.log(`E1 : ${e}`);
    }
  };
  //#endregion

  //#region COUPON CODE DIALOG
  const [openCouponCodeDialog, setOpenCouponCodeDialog] = useState(false);

  const couponCodeDialogCloseFn = (code) => {
    sendDataToIframe({ type: "COUPON", code: code });
    setOpenCouponCodeDialog(false);
  };

  let couponDialogContent = null;

  if (openCouponCodeDialog) {
    couponDialogContent = <CouponDialog closeFn={couponCodeDialogCloseFn} />;
  }

  //#endregion

  //#region EMAIL DIALOG
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const emailDialogCloseFn = (state) => {
    if (state) {
      if (!videoConfirmationDialog) {
        setVideoConfirmationDialog(true);
      }
    } else {
      sendDataToIframe({ type: "EMAIL", status: false });
    }

    setOpenEmailDialog(false);
  };

  let emailDialog = null;

  if (openEmailDialog) {
    emailDialog = <EmailDialog closeFn={emailDialogCloseFn} userToken={userToken} />;
  }

  //#endregion

  //#region VIDEO DIALOG
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const videoDialogCloseFn = (data) => {
    setOpenVideoDialog(false);
    sendDataToIframe(data);
  };

  let videoDialog = null;

  if (openVideoDialog) {
    videoDialog = <VideoDialog closeFn={videoDialogCloseFn} videoUrlRef={videoUrlRef} />;
  }

  //#endregion

  //#region VIDEO CONFIRMATION DIALOG
  const [videoConfirmationDialog, setVideoConfirmationDialog] = useState(false);

  const videoConfirmationDialogCloseFn = (state) => {
    if (state) {
      setOpenVideoDialog(true);
    } else {
      sendDataToIframe({ type: "VIDEO", status: false });
    }

    setVideoConfirmationDialog(false);
  };

  let videoConfirmationDialogContent = null;

  if (videoConfirmationDialog) {
    videoUrlRef.current = null;
    videoConfirmationDialogContent = <VideoConformationDialog closeFn={videoConfirmationDialogCloseFn} videoUrlRef={videoUrlRef} />;
  }

  //#endregion

  //#endregion

  return (
    <Fragment>
      {children}
      {emailDialog}
      {videoDialog}
      {videoConfirmationDialogContent}
      {couponDialogContent}
    </Fragment>
  );
};

export default BasePage;
