import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { keellsBaseUrl } from "../utils/ImiData";

const keellsAPI = createApi({
  reducerPath: "keellsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: keellsBaseUrl,
  }),
  endpoints: (builder) => ({
    getVideoUrl: builder.mutation({
      query: () => ({
        url: `/extra-chance/v1/video`,
        method: "GET",
        headers: {},
      }),
    }),
    updateUserEmail: builder.mutation({
      query: ({ token, email }) => ({
        url: `/extra-chance/v1/email/${token}?email=${email}`,
        method: "POST",
        headers: {},
      }),
      transformResponse: (response) => response?.data,
      transformErrorResponse: (response) => response?.data,
    }),
  }),
});

export const { useGetVideoUrlMutation, useUpdateUserEmailMutation } = keellsAPI;
export default keellsAPI;
