import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getElementWidth } from "../../utils/ScreenManager";

const CouponDialog = ({ closeFn }) => {
  //#region HOOKS
  const [couponCode, setCouponCode] = useState({
    couponCode: "",
    error: false,
    disable: true,
  });

  //#endregion

  //#region UTIL FNS
  const onCouponCodeChanged = (event) => {
    let enteredCouponCode = event.target.value;

    if (enteredCouponCode.length === 0) {
      setCouponCode({
        couponCode: "",
        error: false,
        disable: true,
      });
      return;
    }

    setCouponCode({
      couponCode: enteredCouponCode,
      error: false,
      disable: false,
    });
  };
  //#endregion

  return (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
        <Typography
          style={{
            fontSize: getElementWidth(32),
            textAlign: "left",
            fontWeight: "100",
          }}
        >
          Enter your coupon code
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ padding: getElementWidth(10) }}>
        <TextField
          style={{
            width: "100%",
            borderRadius: "0",
            height: getElementWidth(100),
            fontSize: getElementWidth(30),
            marginBottom: "40px",
            padding: "0px",
          }}
          type="text"
          error={couponCode.error}
          placeholder="coupon code"
          variant="outlined"
          value={couponCode.couponCode}
          onChange={onCouponCodeChanged}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              closeFn(couponCode.couponCode);
            }}
            style={{
              fontSize: getElementWidth(17),
              textTransform: "none",
              alignSelf: "center",
              marginBottom: "10px",
            }}
            disableElevation
            disabled={couponCode.disable}
          >
            Submit
          </Button>
        </div>
        <Typography
          style={{
            fontSize: getElementWidth(17),
            textAlign: "center",
            fontWeight: "normal",
            lineHeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          {}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            closeFn(null);
          }}
        >
          <Typography
            style={{
              textTransform: "none",
              fontSize: getElementWidth(30),
              textAlign: "left",
            }}
          >
            Cancel
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponDialog;
