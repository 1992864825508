import { Backdrop, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetGameDetailsQuery } from "../services/game";
import { homeActions } from "../store/store";
import FeedbackDialog from "../utils/FeedbackDialog";
import { GAME_ID, PathsUrls } from "../utils/ImiData";

export default function HomePage() {
  //#region API && HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading: isLoadingGetGameDetailsAPI, isSuccess: isSuccessGameGameDetailsAPI, data: getGameDetailsAPIData, error: getGameDetailsAPIError } = useGetGameDetailsQuery({ uuid: GAME_ID });

  useEffect(() => {
    if (isSuccessGameGameDetailsAPI) {
      dispatch(homeActions.setGameUrl(getGameDetailsAPIData?.url));
      navigate(PathsUrls.game);
    }
  }, [isSuccessGameGameDetailsAPI]);

  //#endregion

  //#region GET GAME URL

  let getGameDetailsAPIContent;

  if (isLoadingGetGameDetailsAPI) {
    getGameDetailsAPIContent = (
      <Backdrop
        open={true}
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress sx={{ color: "red" }} />
      </Backdrop>
    );
  } else if (isSuccessGameGameDetailsAPI) {
  } else if (getGameDetailsAPIError) {
    getGameDetailsAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    getGameDetailsAPIContent = null;
  }
  //#endregion

  return <Fragment>{getGameDetailsAPIContent}</Fragment>;
}
