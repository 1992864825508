import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUpdateUserEmailMutation } from "../../services/keellsAPI";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";

const EmailDialog = ({ closeFn, userToken }) => {
  //#region HOOKS

  const [email, setEmail] = useState({
    email: "",
    error: false,
    disable: true,
  });

  const [stateIndex, setStateIndex] = useState(1);

  //GET VIDEO API
  const [updateUserEmailFn, { isLoading: isLoadingUpdateUserEmailAPI, error: updateUserEmailAPIError, isSuccess: isSuccessUpdateUserEmailAPI }] = useUpdateUserEmailMutation();

  useEffect(() => {
    if (isSuccessUpdateUserEmailAPI) {
      setStateIndex(0);
      closeFn(true);
    }
  }, [isSuccessUpdateUserEmailAPI]);

  //#endregion

  //#region UTIL FNS
  const onEmailChanged = (event) => {
    let enteredEmail = event.target.value;

    if (enteredEmail.length === 0) {
      setEmail({
        email: "",
        error: false,
        disable: true,
      });
      return;
    }

    var pattern = /\S+@\S+\.\S+/;

    if (pattern.test(enteredEmail)) {
      setEmail({
        email: enteredEmail,
        error: false,
        disable: false,
      });
      return;
    }

    setEmail({
      email: enteredEmail,
      error: true,
      disable: true,
    });

    //onSearch(event.target.value);
  };
  //#endregion

  //#region STATE CONTENT
  let stateContent = null;

  if (stateIndex === 1) {
    stateContent = (
      <Dialog fullWidth={true} open={true}>
        <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
          <Typography
            style={{
              fontSize: getElementWidth(32),
              textAlign: "left",
              fontWeight: "100",
            }}
          >
            Enter your email and watch a video to try your luck again
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: getElementWidth(10) }}>
          <TextField
            style={{
              width: "100%",
              borderRadius: "0",
              height: getElementWidth(100),
              fontSize: getElementWidth(30),
              marginBottom: "40px",
              padding: "0px",
            }}
            type="email"
            error={email.error}
            placeholder="email@address.com"
            variant="outlined"
            value={email.email}
            onChange={onEmailChanged}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setStateIndex(2);
                updateUserEmailFn({ token: userToken.current, email: email.email });
              }}
              style={{
                fontSize: getElementWidth(17),
                textTransform: "none",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              disableElevation
              disabled={email.disable}
            >
              Submit
            </Button>
          </div>
          <Typography
            style={{
              fontSize: getElementWidth(17),
              textAlign: "center",
              fontWeight: "normal",
              lineHeight: "normal",
              whiteSpace: "pre-line",
            }}
          >
            {}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              closeFn(false);
            }}
          >
            <Typography
              style={{
                textTransform: "none",
                fontSize: getElementWidth(30),
                textAlign: "left",
              }}
            >
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (stateIndex === 2) {
    if (isLoadingUpdateUserEmailAPI) {
      stateContent = <LoadingDialog />;
    } else if (updateUserEmailAPIError) {
      console.log(updateUserEmailAPIError);
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setStateIndex(1);
          }}
          data={{
            title: "Oops!",
            msg: updateUserEmailAPIError.errorMessage ?? "Something Went Wrong...!",
          }}
        />
      );
    }
  }

  //#endregion

  return stateContent;
};

export default EmailDialog;
