import { configureStore, createSlice } from "@reduxjs/toolkit";
import gameApi from "../services/game";
import keellsAPI from "../services/keellsAPI";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    gameUrl: "",
  },
  reducers: {
    setGameUrl: (state, action) => {
      state.gameUrl = action.payload;
    },
  },
});

//remove feedBackApi!!
const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    [keellsAPI.reducerPath]: keellsAPI.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(gameApi.middleware).concat(keellsAPI.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
